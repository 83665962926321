function loadVideo(thisObject) {
    //Set Hash Url
    var slug = $(thisObject).attr('data-slug');
    window.location.hash = slug;

    var id = thisObject.attr('data-id');

    //Defaults:
    $('.portfolio-container').show();
    $('.main-index').find('.mdl-cell').not('.hide-cell').show();
    var othersInSeries = $('.others');
    var othersInSeriesDivider = $('.others-divider');
    othersInSeries.empty().hide();
    othersInSeriesDivider.hide();
    $('.thumbnail-image').removeClass('opacity-50');

    var iframeContainer = $('.iframe-container');
    var imageContainer = $('.img-content');
    //Check if video / image:
    if ( thisObject.attr('data-iframe-code') ) {
        var iframeCode = thisObject.attr('data-iframe-code');
        iframeContainer.show().empty().append(iframeCode).fitVids();
        imageContainer.hide();
        scrollWindow($(this));
    } else {
        var imageFullSize = thisObject.attr('data-image-full-size');
        imageContainer.show().find('img').attr('src', imageFullSize);
        iframeContainer.hide();
        $('.img-container img').load(function(){
            setImgHeightAndScroll($(this));
        });
    }

    //Populate Title
    var titlePart1 = thisObject.first().find('.title-prt-1').clone().children().remove().end().text();
    var titlePart2 = thisObject.first().find('.title-prt-2').text();

    var getEpisodeName = thisObject.attr('data-episode-name');
    var episodeName = '';
    if (thisObject.attr('data-episode-name')) {
        episodeName = ' &quot;' + getEpisodeName + '&quot;';
    } else {
        episodeName = '';
    }

    var title = '';
    if (titlePart2.length) {
        title = titlePart1 + ' / ' + titlePart2 + episodeName ;
    } else {
        title = titlePart1;
    }

    $('.portfolio-title h5').text('');
    $('.portfolio-title h5').append(title);

    //Populate excerpt if exists
    var excerpt = thisObject.attr('data-excerpt');
    if (thisObject.attr('data-excerpt')) {
        $('.excerpt').show();
    } else {
        $('.excerpt').hide();
    }
    $('.excerpt').text('');
    $('.excerpt').append(excerpt);

    //Check if series:
    //If series load others in sub grid:
    if ( thisObject.attr('data-series-id') ) {
        var seriesId = thisObject.attr('data-series-id');
        var seriesGroup = thisObject.attr('data-series-group');
        var videoInSeriesInGroup = $('.lnk-portfolio[data-series-id="' + seriesId + '"][data-series-group="' + seriesGroup + '"]');
        var cellInSeries = $('.mdl-cell[data-series-id="' + seriesId + '"]');
        videoInSeriesInGroup.parent().hide();
        cellInSeries.hide();
        var clonedVideo = videoInSeriesInGroup.clone(true, true).appendTo('.others').show();
        clonedVideo.wrap('<div class="mdl-cell cell-content-width mdl-cell--4-col mdl-cell--4-col-tablet"></div>');
        othersInSeries.show();
        othersInSeries.find('[data-id="' + id + '"]').find('.thumbnail-image').addClass('opacity-50');
        othersInSeriesDivider.show();
        //Set grid colors:
        $.getJSON('ajax/grid-colors', function (data) {
            for (var colorIndex = 0; colorIndex < data.length; colorIndex++) {
                othersInSeries.find('figcaption').each(function(divIndex) {
                    var thisObject = $(this);
                    thisObject.removeClass('color--bg');
                    if (divIndex == colorIndex) {
                        thisObject.addClass('color-' + data[colorIndex].hover_color_id + '-bg');
                    }
                });
            }
        });
    } else {
        thisObject.find('.thumbnail-image').addClass('opacity-50');
    }

    //Set data-player-order for next and previous button:
    var playerOrder = parseInt(thisObject.attr('data-player-order'));
    var next = playerOrder + 1;
    $('.lnk-next').attr('data-next', next);
    var previous = playerOrder - 1;
    $('.lnk-prev').attr('data-previous', previous);
}

function setImgHeightAndScroll(thisObject) {
    var height = thisObject.height();

    $('.img-content').height(height);
    var photo_scroll = (height - 475);
    $('.mdl-layout').scrollTop( photo_scroll );
}

function scrollWindow() {
    var portfolio_content_h = $('.iframe-container').height();
    if ( portfolio_content_h < 590  ) {
        $('.mdl-layout').animate({scrollTop: 76}, 400);
    } else {
        $('.mdl-layout').animate({scrollTop: 210}, 400);
    }
}

function bindButtonActions() {
    $('.lnk-portfolio').unbind("click").click( function(e) {
        e.preventDefault();
        loadVideo($(this));
    });
    $('.img-next').unbind("click").click( function(e) {
        e.preventDefault();
        $('.lnk-next').trigger("click");
    });
    $('.img-prev').unbind("click").click( function(e) {
        e.preventDefault();
        $('.lnk-prev').trigger("click");
    });

    var count = parseInt($('.count-portfolios').html());
    var path = window.location.pathname;

    $('.lnk-next').on("click", function(event) {
        event.preventDefault();
        var next = parseInt($(this).attr('data-next'));

        if (next > count) {
            if (path == '/') {
                window.location.href = '/commercial';
            } else if (path == '/commercial') {
                window.location.href = '/tv';
            } else if (path == '/tv') {
                window.location.href = '/photography';
            } else if (path == '/photography') {
                window.location.href = '/';
            }
        } else {
            loadVideo( $('.lnk-portfolio[data-player-order="' + next + '"]').first() );
        }

    });

    $('.lnk-prev').on("click", function(event) {
        event.preventDefault();
        var previous = $(this).attr('data-previous');
        if (previous == 0) {
            if (path == '/') {
                window.location.href = '/photography';
            } else if (path == '/photography') {
                window.location.href = '/tv';
            } else if (path == '/tv') {
                window.location.href = '/commercial';
            } else if (path == '/commercial') {
                window.location.href = '/';
            }
        } else {
            loadVideo( $('.lnk-portfolio[data-player-order="' + previous + '"]').first() );
        }

    });
}

$('select').chosen(
    { width: '100%' }
);

/*$('input').not('.bootstrap-switch-input').iCheck({
    checkboxClass: 'icheckbox_square-grey',
    radioClass: 'iradio_square-grey',
});*/

function triggerUploadCare () {
    $('.upload-button').on('click', function () {
        var id = $(this).attr("data-id");
        var uploadcare_widget = $("#" + id).next(".uploadcare-widget");
        uploadcare_widget.find(".uploadcare-widget-button-open").trigger('click');
    });
}

triggerUploadCare();

function triggerUpload () {
    $('.normal-upload-button').on('click', function () {
        var id = $(this).attr("data-id");
        $("#" + id).trigger('click');
    });
}

triggerUpload();

function removeFromGrid(id) {
    var job = $('.thumbnail-container[data-id="' + id + '"]');
    job.find('input[name*="[grid_order]"]').val('');
    job.addClass('being-removed').hide();
    $(".sortable-grid").trigger("ss-rearrange");
}

function reOrder(thisObject) {
    $('.sortable-grid').shapeshift({
        minColumns: 3
    });
    thisObject.hide();
    $('#save-order-button').show();
    $('#cancel-order-button').show();
    $('#add-button').hide();
    $('#edit-colors-button').hide();
    $('#javascript-alert').fadeIn().find('.message').html('Re-order the position by dragging and dropping a thumbnail into the position of another. You must click on \'Save Order\' to update.');
    $('.thumbnail-container').addClass('is-draggable');
    javascriptAlert();
}

function updateGridOrder(callback) {
    $('.thumbnail-container:not(.being-removed)').each(function() {
        var thisObject  = $(this);
        var gridOrder = thisObject.index() + 1;
        thisObject.find('input[name*="[grid_order]"]').val(gridOrder);
    });
    callback();
}

function postOrder(onPageLoad) {
    var form = $('#order-form');

    $.post(form.attr('action'), form.serialize(), null, 'json')
        .done(function (data) {
            if(data.success == true) {
                if (!onPageLoad) {
                    $('#javascript-alert').fadeIn().find('.message').html('The order has been saved.');
                }
            }
        })
        .fail(function (data) {

        });
}

function saveGridOrder(onPageLoad) {
    event.preventDefault();
    $('#save-order-button').hide();
    $('#cancel-order-button').hide();
    $('#re-order-button').show();
    $('#add-button').show();
    $('#edit-colors-button').show();
    $('.thumbnail-container').removeClass('is-draggable');
    /*$('.index-container .mdl-grid').sortable('disable');
    $('.draggable').draggable('disable');*/
    javascriptAlert();
    hideAlert();
    updateGridOrder(function() {
        postOrder(onPageLoad);
    });
    $('.sortable-grid').trigger('ss-destroy').removeAttr('style');
    $('.sortable-grid').find('.thumbnail-container').removeAttr('style');
}

function javascriptAlert() {
    $('#javascript-alert .close').unbind("click").click(function () {
        var alert = $('#javascript-alert');
        alert.clone().insertAfter('.form-controls').hide();
    });

}

function hideAlert() {
    setTimeout(function() {
        $('.alert').fadeOut()
    }, 20000);
}

/*hideAlert();*/

function checkJobCategoryId(thisObject, create) {
    var value = thisObject.val();
    if (value) {
        $('#add-button').show();
    }
    if (value == 1) {
        $('#title-div').hide();
        $('#title').val('');
        $('#client-div').show();
        $('#sub-title-div').show();
        $('#iframe-code-div').show();
        $('#image-full-size-div').hide();
        if (create == true ) {
            /*$('[name="hidden_under_show_all"]').iCheck('uncheck');*/
            $('[name="hidden_under_show_all"]').prop('checked', false);
            $('label[for="hidden_under_show_all"]').text("Hide this job in the 'Show All' grid");
        }
    } else if (value == 2) {
        $('#title-div').show();
        $('#client-div').hide();
        $('#client').val('');
        $('#sub-title-div').show();
        $('#iframe-code-div').show();
        $('#image-full-size-div').hide();
        if (create == true ) {
            /*$('[name="hidden_under_show_all"]').iCheck('uncheck');*/
            $('[name="hidden_under_show_all"]').prop('checked', false);
            $('label[for="hidden_under_show_all"]').text("Hide this job in the 'Show All' grid");
        }
    } else if (value == 3) {
        $('#image-full-size-div').show();
        $('#iframe-code-div').hide();
        $('#title-div').show();
        $('#client-div').hide();
        $('#client').val('');
        $('#sub-title-div').hide();
        if (create == true ) {
            /*$('[name="hidden_under_show_all"]').iCheck('check');*/
            $('[name="hidden_under_show_all"]').prop('checked', true);
            $('label[for="hidden_under_show_all"]').text("Hide this job in the 'Show All' grid (By default new stills will be " +
                "hidden in the 'Show All' grid.)");
        }
    }
}

$('.datepicker').datetimepicker({
    timepicker: false,
    format:'Y-m-d',
    scrollMonth: false
});

function addRecord(containerId, buttonAdded) {
    $('#' + containerId + '').addClass('loaded');
    var hiddenRecord = $('#' + containerId + '> .record.hidden').clone();

    hiddenRecord.find(".is-upgraded").each(function() {
        $(this).removeClass('is-upgraded');
        $(this).attr('data-upgraded', '');
    })

    var lastLoadedRecord = $('#' + containerId + '> .record.hidden').find('[name="rowCounter"]');
    if (typeof lastLoadedRecord === "undefined") {
        lastLoadedRecord.val(0);
    }
    hiddenRecord.find(':input').each(function() {
        var name = $(this).attr('name');
        if (typeof name !== "undefined" && name.indexOf("[?]") != -1) {
            var record = lastLoadedRecord.val();
            var replace = name.replace("[?]", "[" + record + "]");
            $(this).attr('name', replace);
            if ( $(this).hasClass("mdl-checkbox__input") ) {
                $(this).attr('id', replace);
            } else {
                $(this).attr('id', replace + '_hidden');
            }
            $(this).parent('label').attr('for', replace);
            if (typeof $(this).next('label').attr('for') !== "undefined") {
                $(this).next('label').attr('for', replace);
            }
        }
    });
    hiddenRecord.find('.upload-button').each(function() {
        var dataId = $(this).attr('data-id');
        var record = lastLoadedRecord.val();
        var replace = dataId.replace("?", record);
        $(this).attr('data-id', replace);
    });
    hiddenRecord.find('.image').each(function() {
        var id = $(this).attr('id');
        var record = lastLoadedRecord.val();
        var replace = id.replace("?", record);
        $(this).attr('id', replace);
    });
    hiddenRecord.find('.image-upload').each(function() {
        var record = lastLoadedRecord.val();
        $(this).attr('id', 'image-upload-' + record);
    });
    lastLoadedRecord.val(parseInt(lastLoadedRecord.val()) + 1);
    hiddenRecord.find('[name="rowCounter"]').val(lastLoadedRecord.val());

    var currentRecord = hiddenRecord.clone().appendTo('#' + containerId + '.mdl-grid-container');

    currentRecord.removeClass("hidden");
    currentRecord.find('select').chosen();
    var richTextEditor = currentRecord.find('.rich-text-editor-cloned');
    richTextEditor.summernote({
        toolbar: [
            // [groupName, [list of button]]
            ['style', ['italic', 'bold', 'underline']],
            ['para', ['paragraph']],
            ['insert', ['link']],
        ],
        callbacks: {
            onPaste: function(e) {
                var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                e.preventDefault();
                document.execCommand('insertText', false, bufferText);
            }
        }
    });

    if(buttonAdded) {
        componentHandler.upgradeDom();
    }

    bindAddRecordButtonActions();
    triggerUploadCare();


}

function bindAddRecordButtonActions() {
    $(".delete-button").unbind("click").click(function () {
        $(this).parent().parent().parent().remove();
    });
    $('.add-button').unbind("click").click(function () {
        var containerId = $(this).attr('id').replace('-add-button', '');
        addRecord(containerId, true);
        bindAddRecordButtonActions();
    });
    $('.image-upload').each(function() {
        var image = uploadcare.Widget(this);
        var id = $(this).attr('id');
        image.onUploadComplete(function() {
            var imageSelector = id.replace('-upload', '');
            $('#' + imageSelector + '').show().attr('src', image.inputElement.value).parent().removeClass('square');
        });
    })
}

bindAddRecordButtonActions();