function materialPopover(thisObject) {
    var button = $('.mdl-trigger-popover[data-id="' + thisObject.data('id') + '"]').find('.mdl-button__ripple-container');
    var target = $('.mdl-popover[data-id="' + thisObject.data('id') + '"]');
    console.log(target);
    target.toggleClass('active');

    $(document).mouseup(function (e) {
        var popoverContainer = $('.mdl-popover');
        if ( !button.is(e.target) ) {
            popoverContainer.removeClass('active');
        }
    });
}